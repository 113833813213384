/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface HealthcheckEntity {
  status: string;
}

export interface CreateAddressDto {
  countryId: string;
  streetAddress: string;
  city: string;
  state?: string;
  zipCode?: string;
}

export interface AddressDto {
  id: string;
  countryId: string;
  streetAddress: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  createdAt: string;
  updatedAt: string | null;
}

export interface AddressesDto {
  addresses: AddressDto[];
}

export interface UpdateAddressDto {
  countryId?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export interface CreateUserDto {
  email: string;
  password: string;
}

export interface UserIdDto {
  id: string;
}

export interface UserStatusDto {
  id: string;
  onboardingCompleted: boolean;
  onboardingSkipped: boolean;
}

export interface UserDto {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  createdAt: string;
  updatedAt: string | null;
  personalWorkspaceId: string;
  avatarUrl: string | null;
  status: UserStatusDto;
}

export interface UpdateUserDto {
  firstName?: string | null;
  lastName?: string | null;
  email?: string;
}

export interface UpdatePasswordDto {
  newPassword: string;
  currentPassword: string;
}

export interface PublicResetPasswordDto {
  password: string;
}

export interface UserAvatarDto {
  key: string | null;
}

export interface UpdateUserStatusDto {
  id?: string;
  onboardingCompleted?: boolean;
  onboardingSkipped?: boolean;
}

export interface UpdateUserStatusResponseDto {
  id: string;
}

export interface AuthLoginDto {
  email: string;
  password: string;
}

export interface AuthTokensResponseDto {
  accessToken: string;
  refreshToken: string;
}

export interface AuthRegisterDto {
  email: string;
  password: string;
}

export interface RegisteredUserResponseDto {
  email: string;
}

export interface ResetPasswordDto {
  email: string;
}

export interface ResetPasswordUrlDto {
  resetUrl: string;
}

export interface WorkspaceDto {
  id: string;
  name: string;
  ownerId: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface WorkspacesDto {
  workspaces: WorkspaceDto[];
}

export interface UpdateWorkspaceDto {
  name: string;
}

export interface ArtistDto {
  id: string;
  name: string;
  logoKey: string | null;
  posterKey: string | null;
  workspaceId: string;
  createdAt: string;
  updatedAt: string | null;
  archived: boolean;
}

export interface ArtistsDto {
  artists: ArtistDto[];
}

export interface CreateArtistDto {
  name: string;
  logoKey?: string;
  posterKey?: string;
  workspace_id: string;
}

export interface UpdateArtistDto {
  name?: string;
  logoKey?: string;
  posterKey?: string;
  workspace_id?: string;
}

export interface UploadArtistFileDto {
  fileType: "logo" | "poster";
  workspaceId: string;
}

export interface UploadedFileKeyDto {
  key: string | null;
}

export interface DeleteArtistFileDto {
  fileType: "logo" | "poster";
  workspaceId: string;
}

export interface CreateTourDto {
  name: string;
  posterKey: string;
  workspaceId: string;
  artistId: string;
  /** @format date-time */
  startsAt: string;
  /** @format date-time */
  endsAt: string;
}

export interface TourDto {
  id: string;
  name: string;
  posterKey: string | null;
  startsAt: string | null;
  endsAt: string | null;
  workspaceId: string;
  artistId: string;
  archived: boolean;
}

export interface TourArtistDto {
  id: string;
  name: string;
}

export interface TourWithRoleAndArtistDto {
  id: string;
  name: string;
  posterKey: string | null;
  startsAt: string | null;
  endsAt: string | null;
  workspaceId: string;
  artistId: string;
  archived: boolean;
  artist: TourArtistDto;
  role: "TOUR_MEMBER" | "TOUR_MANAGER" | "TOUR_OWNER";
}

export interface TourWithArtistDto {
  id: string;
  name: string;
  posterKey: string | null;
  startsAt: string | null;
  endsAt: string | null;
  workspaceId: string;
  artistId: string;
  archived: boolean;
  artist: TourArtistDto;
}

export interface ToursWithArtistDto {
  tours: TourWithArtistDto[];
}

export interface UpdateTourDto {
  name?: string;
  posterKey?: string;
  workspaceId?: string;
  artistId?: string;
  /** @format date-time */
  startsAt?: string;
  /** @format date-time */
  endsAt?: string;
}

export interface TourPersonnelUserDto {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  avatarKey: string | null;
  phoneNumber: string | null;
}

export interface TourPersonnelDto {
  id: string;
  tourId: string;
  workspaceMemberId: string;
  roleId: string;
  createdAt: string;
  updatedAt: string | null;
  user: TourPersonnelUserDto;
}

export interface TourPersonnelArrayDto {
  tourPersonnel: TourPersonnelDto[];
}

export interface Person {
  /** @example "user@example.com" */
  email: string;
  /** @example "role_id_123" */
  roleId: string;
}

export interface CreateTourPersonnelDto {
  people: Person[];
}

export interface UpdateTourPersonnelDto {
  workspaceMemberId: string;
  tourId: string;
  roleId: string;
}

export interface UploadTourFileDto {
  fileType: "poster";
  workspaceId: string;
}

export interface DeleteTourFileDto {
  fileType: "poster";
  workspaceId: string;
}

export interface Measurements {
  clothesCutPreference: ("male" | "female" | "unisex")[];
  shirtSize: string;
  casualPantsSize: string;
  chestSize: string;
  waistSize: string;
  hipsSize: string;
  inseamSize: string;
  sleeveLength: string;
  neckSize: string;
  shoeSize: string;
}

export interface EducationalInformation {
  highestLevelOfEducation: string;
  degreeType: string;
  yearsObtained: string;
  institution: string;
}

export interface PersonalDocuments {
  headshotKey?: string;
  covidVaccinationKey?: string;
  driversLicenseKey?: string;
  w9Key?: string;
}

export interface DietaryInformation {
  dietaryRestrictions?: string[];
  materialAllergies?: string;
  medicinalAndNutritionalAllergies?: string;
}

export interface CountriesPersonalDetailsCitizenshipDto {
  id: string;
}

export interface PersonalDetailDto {
  id: string;
  userId: string;
  completeLegalName: string | null;
  preferredName: string | null;
  gender: string | null;
  birthCity: string | null;
  birthCountryId: string | null;
  phoneNumber: string | null;
  measurements: Measurements | null;
  educationalInformation: EducationalInformation | null;
  personalDocuments: PersonalDocuments | null;
  dietaryInformation: DietaryInformation | null;
  addresses: AddressDto[] | null;
  citizenship: CountriesPersonalDetailsCitizenshipDto[] | null;
  createdAt: string;
  updatedAt: string | null;
  birthDate: string | null;
}

export interface UpdatePersonalDetailDto {
  completeLegalName?: string;
  preferredName?: string;
  gender?: string;
  birthCity?: string;
  birthCountryId?: string;
  /** @format date-time */
  birthDate?: string;
  phoneNumber?: string;
  measurements?: Measurements;
  educationalInformation?: EducationalInformation;
  personalDocuments?: PersonalDocuments;
  dietaryInformation?: DietaryInformation;
  addressIds?: string[];
}

export interface PersonalDocumentDto {
  documentType: "headshot" | "covidVaccination" | "driversLicense" | "w9";
}

export interface DeletePersonalDocumentDto {
  documentType: "headshot" | "covidVaccination" | "driversLicense" | "w9";
}

export interface CreateUserCitizenshipDto {
  countryIds: string[];
}

export interface CreateTourPersonnelRoleDto {
  role: string;
}

export interface TourPersonnelRoleDto {
  id: string;
  role: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface TourPersonnelRolesDto {
  tourPersonnelRoles: TourPersonnelRoleDto[];
}

export interface UpdateTourPersonnelRoleDto {
  role?: string;
}

export interface ClearNumbers {
  redressNumber: string;
  knownTravelerNumber: string;
}

export interface AirlineFrequentFliers {
  airline: string;
  flyerNumber: string;
}

export interface Passports {
  passportKey: string | null;
  passportNumber: string;
  issueDate: string;
  expirationDate: string;
  issuingCountryId: string;
}

export interface TravellingDetailDto {
  id: string;
  userId: string;
  homeAirport: string | null;
  seatPreference: ("aisle" | "window" | "middle")[];
  roomType: ("single" | "double" | "king_room" | "studio" | "suite")[];
  clearNumbers: ClearNumbers[];
  airlineFrequentFliers: AirlineFrequentFliers[];
  passports: Passports[];
  createdAt: string;
  updatedAt: string | null;
  roomFacilities: "smoking" | "non_smoking";
}

export interface UpdateTravellingDetailDto {
  homeAirport?: string;
  seatPreference?: ("aisle" | "window" | "middle")[];
  roomType?: ("single" | "double" | "king_room" | "studio" | "suite")[];
  clearNumbers?: ClearNumbers[];
  airlineFrequentFliers?: AirlineFrequentFliers[];
  passports?: Passports[];
  roomFacilities?: "smoking" | "non_smoking";
}

export interface AddPassportDto {
  passportNumber: string;
  issueDate: string;
  expirationDate: string;
  issuingCountryId: string;
}

export interface DeletePassportDto {
  passportNumber: string;
}

export interface CreateEmergencyContactDto {
  firstName: string;
  lastName: string;
  relation: string;
  email: string;
  phoneNumber: string;
  address: CreateAddressDto;
}

export interface EmergencyContactDto {
  id: string;
  userId: string;
  addressId: string;
  firstName: string | null;
  lastName: string | null;
  relation: string | null;
  email: string | null;
  phoneNumber: string | null;
  createdAt: string;
  updatedAt: string | null;
  address: AddressDto;
}

export interface EmergencyContactsDto {
  emergencyContacts: EmergencyContactDto[];
}

export interface UpdateEmergencyContactDto {
  firstName?: string;
  lastName?: string;
  relation?: string;
  email?: string;
  phoneNumber?: string;
  address: UpdateAddressDto;
}

export interface UsersDto {
  users: UserDto[];
}

export interface ToursDto {
  tours: TourDto[];
}

export interface CreateEventTypeDto {
  name: string;
}

export interface EventTypeDto {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface EventsDto {
  /**
   * Events grouped by date
   * @example {"2024-04-12":[{"id":"event1"},{"id":"event2"}],"2024-04-13":[]}
   */
  events: Record<string, EventDto[]>;
}

export interface FileKeyDto {
  key: string;
}

export interface UploadedFileDto {
  url: string;
}

export interface DuplicateEventDto {
  /** @format date-time */
  initialDate: string;
  /** @format date-time */
  targetDate: string;
  tourId: string;
}

export interface EventDetails {
  name: string;
  description: string;
}

export interface EventProviderDto {
  id: string;
  workspaceId: string;
  contactName: object;
  email: string | null;
  title: string | null;
  phoneNumber: string | null;
  address: AddressDto;
  createdAt: string;
  updatedAt: string | null;
}

export interface AirportInfoDto {
  code: string;
  codeIcao: string;
  codeIata: string;
  timezone: string;
  name: string;
  city: string;
  airportInfoUrl: string;
}

export interface FlightInfoDto {
  faFlightId: string;
  ident: string;
  identIcao: string;
  identIata: string;
  actualIdent: string;
  actualIdentIcao: string;
  actualIdentIata: string;
  aircraftType: string;
  scheduledIn: string;
  scheduledOut: string;
  origin: AirportInfoDto;
  destination: AirportInfoDto;
}

export interface EventTravelAdditionalInformation {
  departureOrigin: string | null;
  arrivalOrigin: string | null;
  departureTime: string | null;
  destination: string | null;
  arrivalTime: string | null;
  travelType: "ground" | "air" | "rail" | "sea" | null;
  departureOriginAirportCode: string | null;
  arrivalOriginAirportCode: string | null;
  flightNumber: string | null;
  trainNumber: string | null;
  sealNumber: string | null;
  ticketsStatus: "bought" | "missing" | null;
  flightInfo: FlightInfoDto | null;
}

export interface EventLoadInAdditionalInformation {
  requiredEquipment: string | null;
}

export interface EventPromoAdditionalInformation {
  mediaOutlet: string | null;
  interviewerName: string | null;
}

export interface EventHotelAdditionalInformation {
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  keyContacts: string | null;
  facilities: string[] | null;
  hotelName: string | null;
}

export interface EventAdditionalInformation {
  travel: EventTravelAdditionalInformation | null;
  loadIn: EventLoadInAdditionalInformation | null;
  promo: EventPromoAdditionalInformation | null;
  hotel: EventHotelAdditionalInformation | null;
}

export interface EventPersonnel {
  id: string;
  userId: string | null;
  roleId: string;
  email: string | null;
}

export interface Group {
  id: string;
  eventPersonnelId: string;
  eventPersonnelRoleId: string;
  personnel: EventPersonnel[];
}

export interface EventDto {
  id: string;
  tourId: string;
  eventTypeId: string;
  countryId: string;
  state: string;
  city: string;
  visibleToAll: boolean;
  eventDetails: EventDetails | null;
  eventProvider: EventProviderDto | null;
  startDate: string | null;
  endDate: string | null;
  createdAt: string;
  updatedAt: string | null;
  additionalInformation: EventAdditionalInformation | null;
  notes: string | null;
  eventPersonnel: EventPersonnel[];
  streetAddress: string | null;
  zipCode: string | null;
  groups: Group[] | null;
  timezoneId: string | null;
}

export interface CreateEventDto {
  eventTypeId: string;
  countryId: string;
  state: string;
  city: string;
  zipCode?: string;
  streetAddress?: string;
  eventDetails?: EventDetails;
  additionalInformation?: EventAdditionalInformation;
  notes?: string;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  timezoneId: string;
}

export interface EventTypesDto {
  eventTypes: EventTypeDto[];
}

export interface UpdateEventDto {
  eventTypeId?: string;
  countryId?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  streetAddress?: string;
  eventDetails?: EventDetails;
  additionalInformation?: EventAdditionalInformation;
  notes?: string;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  timezoneId?: string;
}

export interface CreateEventPersonnelDto {
  people: Person[];
}

export interface PersonnelGroup {
  groupId: string;
  roleId: string;
}

export interface CreateEventPersonnelGroupDto {
  groups: PersonnelGroup[];
}

export interface EventPersonnelUserDto {
  id: string;
  email: string;
  avatarKey: string | null;
}

export interface EventPersonnelDto {
  id: string;
  eventId: string;
  tourPersonnelId: string | null;
  eventPersonnelRoleId: string;
  createdAt: string;
  updatedAt: string | null;
  user: EventPersonnelUserDto | null;
}

export interface EventPersonnelArrayDto {
  eventPersonnel: EventPersonnelDto[];
}

export interface UpdateEventPersonnelDto {
  eventId: string;
  eventPersonnelRoleId: string;
}

export interface CreateEventProviderDto {
  workspaceId: string;
  contactName: string;
  email: string;
  title: string;
  phoneNumber: string;
  address: CreateAddressDto;
}

export interface UpdateEventProviderDto {
  contactName?: string;
  email?: string;
  title?: string;
  phoneNumber?: string;
  workspaceId: string;
  address: UpdateAddressDto;
}

export interface EventProvidersDto {
  eventProviders: EventProviderDto[];
}

export interface DayTagDto {
  id: string;
  workspaceId: string | null;
  name: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface DayTagsDto {
  dayTags: DayTagDto[];
}

export interface CreateDayTagDto {
  workspaceId: string;
  name: string;
}

export interface DayTag {
  name: string;
}

export interface TourDayNoteDto {
  id: string;
  tourDayId: string;
  body: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface TourDayDto {
  id: string;
  tourId: string;
  dayTagId: string;
  date: string;
  createdAt: string;
  updatedAt: string | null;
  dayTag: DayTag;
  note: TourDayNoteDto;
}

export interface TourDaysDto {
  tourDays: TourDayDto[];
}

export interface CreateTourDayDto {
  workspaceId: string;
  dayTagId: string;
  date: string;
}

export interface CreatedTourDayDto {
  id: string;
  tourId: string;
  dayTagId: string;
  date: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface CreateTourDayNoteDto {
  body: string;
  tourDayId: string;
}

export interface UpdateTourDayNoteDto {
  body?: string;
  tourDayId?: string;
}

export interface EventPersonnelRoleDto {
  id: string;
  role: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface EventPersonnelRolesDto {
  eventPersonnelRoles: EventPersonnelRoleDto[];
}

export interface TableColumnsDto {
  /**
   * Columns grouped by table name
   * @example {"table1":["column1","column2","column3"],"table2":["column4","column5"]}
   */
  tableColumns: Record<string, string[]>;
}

export interface TableColumnsSupplementalQuestionsDto {
  /**
   * Supplemental questions grouped by country code
   * @example {"DE":[{"id":"question3-id","body":"B question"}],"PL":[{"id":"question2-id","body":"A question"},{"id":"question1-id","body":"C question"}],"SK":[{"id":"question2-id","body":"A question"}]}
   */
  supplementalQuestions: Record<
    string,
    {
      id?: string;
      body?: string;
    }[]
  >;
}

export interface CreateReportTemplateDto {
  workspaceId: string;
  name: string;
  columns: Record<string, string[]>;
  supplementalQuestions?: Record<
    string,
    {
      id?: string;
      body?: string;
    }[]
  >;
}

export interface ReportTemplateDto {
  workspaceId: object;
  name: string;
  columns: Record<string, string[]>;
  supplementalQuestions: Record<
    string,
    {
      id?: string;
      body?: string;
    }[]
  >;
}

export interface ReportTemplatesDto {
  reportTemplates: ReportTemplateDto[];
}

export interface CreateReportDto {
  reportTemplateId: string | null;
  workspaceId: string;
  tourId: string;
  people?: string[] | null;
  from?: string | null;
  to?: string | null;
  columnsOrder: string[];
  columns: Record<string, string[]>;
  supplementalQuestions?: Record<
    string,
    {
      id?: string;
      body?: string;
    }[]
  >;
}

export interface CreatedReportDto {
  reportTemplateId: string | null;
  workspaceId: string;
  columnsOrder: string[];
  content: object;
}

export interface ReportsDto {
  reports: CreatedReportDto[];
}

export interface CreateGroupDto {
  name: string;
}

export interface GroupMemberUserDto {
  id: string;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  email: string;
  avatarKey: string | null;
}

export interface GroupMemberDto {
  id: string;
  tourPersonnelId: string;
  groupId: string;
  tourPersonnelRoleId: string;
  createdAt: string;
  updatedAt: string | null;
  user: GroupMemberUserDto;
}

export interface GroupDto {
  id: string;
  name: string;
  tourId: string;
  createdAt: string;
  updatedAt: string | null;
  groupMembers: GroupMemberDto[];
}

export interface GroupsDto {
  groups: GroupDto[];
}

export interface UpdateGroupDto {
  name?: string;
}

export interface CreateGroupMembersDto {
  people: Person[];
}

export interface GroupMembersArrayDto {
  groupMembers: GroupMemberDto[];
}

export interface RiderTypeDto {
  id: string;
  name: string;
  createdAt: string;
  updatedAt?: string | null;
}

export interface RiderTypesDto {
  riderTypes: RiderTypeDto[];
}

export interface CreateRiderDto {
  riderTypeId: string;
  name: string;
  tourId: string;
  artistId: string;
}

export interface RiderVenue {
  accessPreferences?: string | null;
  accessQuestions?: string | null;
  loadInPreferences?: string | null;
  loadInQuestions?: string | null;
  securityRequirements?: string | null;
}

export interface RiderTransportation {
  semis?: string | null;
  tourBuses?: string | null;
  boxTrucks?: string | null;
  suvs?: string | null;
  personalVehicles?: string | null;
}

export interface RiderTechnical {
  stageSize?: string | null;
  stagePlotKey?: string | null;
  riggingPlotKey?: string | null;
  scenicKey?: string | null;
  lightingPlotKey?: string | null;
  risers?: string | null;
  powerNeeds?: string | null;
  backDrop?: string | null;
  videoRequirements?: string | null;
  carryingVideo?: boolean | null;
  lightingSystemRequirements?: string | null;
  carryingLighting?: boolean | null;
  audioRequirements?: string | null;
  carryingAudio?: boolean | null;
  sfxRequirements?: string | null;
  backlineRequirements?: string | null;
}

export interface RiderHospitality {
  greenRoomOfficeNeeds?: string | null;
  preferredVipAccommodations?: string | null;
  preferredLaundry?: string | null;
  preferredShowerAndRestrooms?: string | null;
  cateringPreferences?: string | null;
  hospitality?: string | null;
  handTowelsNeeded?: string | null;
  bathTowelsNeeded?: string | null;
  locationForTowels?: string | null;
  towelColor?: string | null;
}

export interface RiderMerch {
  travellingWithMerchSalesPreps?: boolean | null;
  preferToSellMerch?: boolean | null;
  travellingWithSponsorship?: boolean | null;
  sponsorship?: string | null;
  dailyObligationsForSponsorship?: boolean | null;
  sponsorshipObligations?: string | null;
  sponsorRequireFromVenue?: boolean | null;
  sponsorVenueRequirements?: string | null;
}

export interface RiderDto {
  id: string;
  riderTypeId: string;
  tourId: string;
  artistId: string;
  name: string;
  totalTourPartyNumber: object | null;
  riderVenue: RiderVenue | null;
  riderTransportation: RiderTransportation | null;
  riderTechnical: RiderTechnical | null;
  riderHospitality: RiderHospitality | null;
  riderMerch: RiderMerch | null;
  withSupportingAct: boolean;
}

export interface RidersDto {
  riders: RiderDto[];
}

export interface UpdateRiderDto {
  riderTypeId?: string;
  name?: string;
  tourId?: string;
  artistId?: string;
  totalTourPartyNumber?: number;
  riderVenue?: RiderVenue;
  riderTransportation?: RiderTransportation;
  riderTechnical?: RiderTechnical;
  riderHospitality?: RiderHospitality;
  riderMerch?: RiderMerch;
  withSupportingAct?: boolean;
}

export interface CreateRiderPersonnelDto {
  people: Person[];
}

export interface RiderPersonnelUserDto {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  avatarKey: string | null;
}

export interface RiderPersonnelDto {
  id: string;
  riderId: string;
  tourPersonnelId: string | null;
  createdAt: string;
  updatedAt: string | null;
  user: RiderPersonnelUserDto | null;
}

export interface RiderPersonnelArrayDto {
  riderPersonnel: RiderPersonnelDto[];
}

export interface CreateExternalRiderPersonnelDto {
  riderId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  position?: string;
  company?: string;
}

export interface ExternalRiderPersonnelDto {
  id: string;
  riderId: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  position: string | null;
  company: string | null;
  createdAt: string;
  updatedAt: string | null;
}

export interface ExternalRiderPersonnelArrayDto {
  externalRiderPersonnel: ExternalRiderPersonnelDto[];
}

export interface UpdateExternalRiderPersonnelDto {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  position?: string;
  company?: string;
}

export interface UploadRiderFileDto {
  fileType: "scenic" | "stagePlot" | "riggingPlot" | "lightingPlot";
}

export interface DeleteRiderFileDto {
  fileType: "scenic" | "stagePlot" | "riggingPlot" | "lightingPlot";
}

export interface CreateRiderLaborDto {
  riderId: string;
  riderLaborTypeId: string;
  quantity: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface RiderLaborDto {
  id: string;
  riderId: string;
  riderLaborTypeId: string;
  quantity: number;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface RiderLaborsDto {
  riderLabors: RiderLaborDto[];
}

export interface UpdateRiderLaborDto {
  riderLaborTypeId?: string;
  quantity?: number;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
}

export interface CreateRiderSupportingActDto {
  riderId: string;
  complementaryNotes?: string;
}

export interface SupportingActPersonnelDto {
  id: string;
  supportingActId: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  position: string | null;
  company: string | null;
  createdAt: string;
  updatedAt: string | null;
}

export interface RiderSupportingActDto {
  id: string;
  riderId: string;
  complementaryNotes: string | null;
  logoKey: string | null;
  riderKey: string | null;
  stagePlotKey: string | null;
  createdAt: string;
  updatedAt: string | null;
  personnel: SupportingActPersonnelDto[];
}

export interface RiderSupportingActsDto {
  riderSupportingActs: RiderSupportingActDto[];
}

export interface UpdateRiderSupportingActDto {
  complementaryNotes?: string;
}

export interface UploadRiderSupportingActFileDto {
  fileType: "logo" | "rider" | "stagePlot";
}

export interface DeleteRiderSupportingActFileDto {
  fileType: "logo" | "rider" | "stagePlot";
}

export interface CreateSupportingActPersonnelDto {
  supportingActId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  position?: string;
  company?: string;
}

export interface SupportingActPersonnelArrayDto {
  supportingActPersonnel: SupportingActPersonnelDto[];
}

export interface UpdateSupportingActPersonnelDto {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  position?: string;
  company?: string;
}

export interface CreatePersonalEventDto {
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  endTime: string;
  name: string;
  notes?: string;
  timezone?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  countryId?: string;
}

export interface PersonalEventDto {
  id: string;
  userId: string;
  name: string;
  notes: string | null;
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  endTime: string;
  timezone: string | null;
  streetAddress: string | null;
  city: string | null;
  state: string | null;
  countryId: string | null;
  createdAt: string;
  updatedAt: string | null;
}

export interface PersonalEventsDto {
  personalEvents: PersonalEventDto[];
}

export interface UpdatePersonalEventDto {
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
  name?: string;
  notes?: string;
  timezone?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  countryId?: string;
}

export interface CreateChatRoomDto {
  tourId: string | null;
  name: string;
}

export interface ChatRoomMemberDto {
  id: string;
  chatRoomId: string;
  userId: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  avatarKey: string | null;
  lastVisitAt: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface ChatRoomDto {
  id: string;
  tourId: string | null;
  ownerId: string;
  name: string;
  archivedAt: string | null;
  createdAt: string;
  updatedAt: string | null;
  members: ChatRoomMemberDto[];
}

export interface ChatRoomsDto {
  chatRooms: ChatRoomDto[];
}

export interface UnreadMessageDto {
  chatRoomId: string;
  count: number;
}

export interface UnreadMessagesDto {
  unreadMessages: UnreadMessageDto[];
}

export interface UpdateChatRoomDto {
  tourId?: string | null;
  name?: string;
}

export interface CreateChatRoomMembersDto {
  people: Person[];
}

export interface CreateMessageDto {
  body?: string | null;
}

export interface SenderDto {
  firstName: string | null;
  lastName: string | null;
  avatarKey: string | null;
}

export interface MessageAttachmentDto {
  id: string;
  messageId: string;
  key: string;
  description: string | null;
  createdAt: string;
  updatedAt: string | null;
}

export interface MessageDto {
  id: string;
  senderId: string;
  sender: SenderDto;
  body: string | null;
  createdAt: string;
  updatedAt: string | null;
  attachments: MessageAttachmentDto[];
}

export interface MessagesDto {
  messages: MessageDto[];
}

export interface CreateAccommodationRoomDto {
  roomNumber: string;
}

export interface RoomMemberDto {
  id: string;
  userId: string;
  roomId: string;
  tourPersonnelId: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  avatarKey: string | null;
  checkIn: string;
  checkOut: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface AccommodationRoomDto {
  id: string;
  eventId: string;
  roomNumber: string;
  createdAt: string;
  updatedAt: string | null;
  members: RoomMemberDto[];
}

export interface AccommodationRoomsDto {
  accommodationRooms: AccommodationRoomDto[];
}

export interface UpdateAccommodationRoomDto {
  roomNumber?: string;
}

export interface CreateRoomMembersDto {
  people: Person[];
}

export interface UpdateRoomMemberDto {
  /** @format date-time */
  checkIn: string;
  /** @format date-time */
  checkOut: string;
}

export interface CreatedTourFileDto {
  fileId: string;
  tourFileId: string;
}

export interface FileDto {
  id: string;
  name: string;
  key: string;
  userId: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface TourFileDto {
  id: string;
  tourId: string;
  fileId: string;
  file: FileDto;
  tour: TourDto;
  artist: ArtistDto;
  createdAt: string;
  updatedAt: string | null;
}

export interface TourFilesDto {
  tourFiles: TourFileDto[];
}

export interface CreateSupplementalAnswerDto {
  body?: string | null;
  questionId: string;
}

export interface UpdateSupplementalAnswerDto {
  body?: string | null;
}

export interface SupplementalQuestionDto {
  id: string;
  body: string;
  createdAt: string;
  updatedAt: string | null;
}

export interface SupplementalAnswerDto {
  id: string;
  userId: string;
  questionId: string;
  question: SupplementalQuestionDto;
  body: string | null;
  fileId: string | null;
  file: FileDto | null;
  createdAt: string;
  updatedAt: string | null;
}

export interface SupplementalAnswersDto {
  /**
   * Answers grouped by country code
   * @example {"PL":[{"id":"id","userId":"userId","questionId":"questionId","question":{"id":"questionId","title":"Question 1","createdAt":"2024-07-11T10:00:00Z"},"body":"Answer body 1","fileId":"fileId","file":{"id":"fileOd","name":"File 1","key":"file1key","userId":"userId","createdAt":"2024-07-11T10:00:00Z","updatedAt":null},"createdAt":"2024-07-11T10:00:00Z","updatedAt":null}]}
   */
  answers: Record<string, SupplementalAnswerDto[]>;
}

export interface SupplementalQuestionsDto {
  supplementalQuestions: SupplementalQuestionDto[];
}

export interface CreateDayFileDto {
  tourDayId: string;
  tourFileIds: string[];
  tourId: string;
}

export interface DayFileDto {
  id: string;
  tourDayId: string;
  tourFileId: string;
  tourFile: TourFileDto;
  createdAt: string;
  updatedAt: string | null;
}

export interface DayFilesDto {
  tourDayId: string;
  tourDay: TourDayDto;
  dayFiles: DayFileDto[];
}

export interface CreateRiderLaborTypeDto {
  name: string;
  tourId: string;
}

export interface RiderLaborTypeDto {
  id: string;
  name: string;
  tourId?: string | null;
  createdAt: string;
  updatedAt?: string | null;
}

export interface RiderLaborTypesDto {
  riderLaborTypes: RiderLaborTypeDto[];
}

export interface GeneralProductionTrackerContent {
  generalAdvanceSent?: boolean | null;
  techRiderSent?: boolean | null;
  hospitalitySent?: boolean | null;
  venueTechPackReceived?: boolean | null;
  date?: string | null;
  venue?: string | null;
  city?: string | null;
  loadInTime?: string | null;
  soundCheck?: string | null;
  changeOver?: string | null;
  setTime?: string | null;
  hardCurfew?: string | null;
  stageManagerContact?: string | null;
  festivalPmContact?: string | null;
}

export interface StagingProductionTrackerContent {
  stageName?: string | null;
  stageSize?: string | null;
  wings?: string | null;
  rollingRisers?: string | null;
}

export interface VideoProductionTrackerContent {
  upstageWall?: string | null;
  imagWalls?: string | null;
  sfx?: string | null;
  power?: string | null;
}

export interface LightingProductionTrackerContent {
  lightingAdvanceContact?: string | null;
  lxPlotReceived?: boolean | null;
  patchReceived?: boolean | null;
  sfx?: string | null;
  power?: string | null;
  networking?: string | null;
}

export interface AudioProductionTrackerContent {
  frequencyLicensesSent?: string | null;
  snake?: string | null;
  foxBoard?: string | null;
  monitors?: string | null;
  maxDb?: string | null;
  strict?: string | null;
  power?: string | null;
}

export interface LaborProductionTrackerContent {
  notes?: string | null;
  forklifts?: string | null;
  dock?: string | null;
  loadIn?: string | null;
}

export interface LoadInProductionTrackerContent {
  riggers?: string | null;
  forks?: string | null;
  loaders?: string | null;
  hands?: string | null;
  allDay?: string | null;
  showHands?: string | null;
  spotOps?: string | null;
}

export interface LoadOutProductionTrackerContent {
  riggers?: string | null;
  forks?: string | null;
  loaders?: string | null;
  hands?: string | null;
}

export interface ProductionTrackerContent {
  general: GeneralProductionTrackerContent;
  staging: StagingProductionTrackerContent;
  video: VideoProductionTrackerContent;
  lighting: LightingProductionTrackerContent;
  audio: AudioProductionTrackerContent;
  labor: LaborProductionTrackerContent;
  loadIn: LoadInProductionTrackerContent;
  loadOut: LoadOutProductionTrackerContent;
}

export interface CreateProductionTrackerDto {
  eventId: string;
  tourId: string;
  content: ProductionTrackerContent;
}

export interface ProductionTrackerDto {
  id: string;
  eventId: string;
  content: ProductionTrackerContent;
  createdAt: string;
  updatedAt: string | null;
}

export interface UpdateProductionTrackerDto {
  tourId: string;
  content: ProductionTrackerContent;
}

export interface FlightsInfoDto {
  flights: FlightInfoDto[];
}

export interface UserPassports {
  firstName: object;
  lastName: object;
  email: string;
  passports: Passports[];
}

export interface ExpiringPassportsDto {
  tourId: string;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  usersExpiringPassports: UserPassports[];
}

export interface SendNotificationDto {
  title: string;
  body: string;
  data: object;
  deviceIds: string[];
}

export interface CreateMobileDeviceDto {
  deviceToken: string;
}

export interface QueryPlacesDto {
  textQuery: string;
}

export interface DisplayNameDto {
  text: string;
  languageCode: string;
}

export interface LocationDto {
  latitude: number;
  longitude: number;
}

export interface PlusCodeDto {
  globalCode: string;
  compoundCode: string;
}

export interface AddressComponentDto {
  types: string[];
  shortText: string;
  longText: string;
  languageCode: string;
}

export interface PlaceDto {
  displayName: DisplayNameDto;
  name: string;
  location: LocationDto;
  utcOffsetMinutes: number;
  plusCode?: PlusCodeDto;
  addressComponents: AddressComponentDto[];
  formattedAddress: string;
  shortFormattedAddress: string;
  types: string[];
}

export interface GooglePlacesDto {
  places: PlaceDto[];
}

export interface GoogleTimezoneDto {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZoneId: string;
  timeZoneName: string;
}

export interface ProductDto {
  id: string;
  object: string;
  active: boolean;
  description: object;
  images: string[];
  name: string;
}

export interface RecurringDto {
  interval: string;
  intervalCount: number;
}

export interface PriceDto {
  id: string;
  object: string;
  active: boolean;
  product: ProductDto;
  recurring: RecurringDto | null;
  type: string;
  unitAmount: number;
  unitAmountDecimal: string;
}

export interface PricesDto {
  data: PriceDto[];
  hasMore: boolean;
  url: string;
}

export interface CreateCheckoutSessionDto {
  stripePriceId: string;
  receiverEmail?: string;
}

export interface CheckoutUrlDto {
  url: string;
}

export interface StripeSubscriptionDto {
  id: string;
  userId: string;
  sponsorId: string;
  stripeSubscriptionId: string;
  stripePriceId: string;
  stripeProductId: string;
  status: string;
  /** @format date-time */
  periodStart: string;
  /** @format date-time */
  periodEnd: string;
  interval: string;
  pausedUntil: string | null;
  cancelsAt: string | null;
  metadata: object;
  createdAt: string;
  updatedAt: string | null;
}

export interface StripeSubscriptionsDto {
  subscriptions: StripeSubscriptionDto[];
}

export interface PauseStripeSubscriptionDto {
  stripeSubscriptionId: string;
  /** @format date-time */
  pausedUntil?: string;
}

export interface ResumeStripeSubscriptionDto {
  stripeSubscriptionId: string;
}

export interface CancelStripeSubscriptionDto {
  stripeSubscriptionId: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title GoForShow API
 * @version 1.0
 * @contact
 *
 * GoForShow API description
 */
export class BaseApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  healthcheck = {
    /**
     * No description
     *
     * @name AppControllerGetHello
     * @request GET:/healthcheck
     */
    appControllerGetHello: (params: RequestParams = {}) =>
      this.request<HealthcheckEntity, any>({
        path: `/healthcheck`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  addresses = {
    /**
     * No description
     *
     * @name AddressesControllerCreate
     * @request POST:/addresses
     */
    addressesControllerCreate: (data: CreateAddressDto, params: RequestParams = {}) =>
      this.request<AddressDto, any>({
        path: `/addresses`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AddressesControllerFindMany
     * @request GET:/addresses
     */
    addressesControllerFindMany: (params: RequestParams = {}) =>
      this.request<AddressesDto, any>({
        path: `/addresses`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AddressesControllerFindOne
     * @request GET:/addresses/{id}
     */
    addressesControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<AddressDto, any>({
        path: `/addresses/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AddressesControllerUpdate
     * @request PATCH:/addresses/{id}
     */
    addressesControllerUpdate: (id: string, data: UpdateAddressDto, params: RequestParams = {}) =>
      this.request<AddressDto, any>({
        path: `/addresses/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AddressesControllerRemove
     * @request DELETE:/addresses/{id}
     */
    addressesControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/addresses/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @name UsersControllerCreate
     * @request POST:/users
     */
    usersControllerCreate: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<UserIdDto, any>({
        path: `/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerGetCurrentUser
     * @request GET:/users/current-user
     */
    usersControllerGetCurrentUser: (params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/users/current-user`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerUpdateUser
     * @request PATCH:/users/current-user
     */
    usersControllerUpdateUser: (data: UpdateUserDto, params: RequestParams = {}) =>
      this.request<UserIdDto, any>({
        path: `/users/current-user`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerUpdatePassword
     * @request PATCH:/users/update-password
     */
    usersControllerUpdatePassword: (data: UpdatePasswordDto, params: RequestParams = {}) =>
      this.request<UserIdDto, any>({
        path: `/users/update-password`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerResetPassword
     * @request PATCH:/users/reset-password
     */
    usersControllerResetPassword: (
      query: {
        email: string;
        token: string;
      },
      data: PublicResetPasswordDto,
      params: RequestParams = {},
    ) =>
      this.request<UserIdDto, any>({
        path: `/users/reset-password`,
        method: "PATCH",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UsersControllerUploadFile
     * @request POST:/users/upload_avatar
     */
    usersControllerUploadFile: (params: RequestParams = {}) =>
      this.request<UserAvatarDto, any>({
        path: `/users/upload_avatar`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserStatusControllerUpdate
     * @request PATCH:/users/status/{id}
     */
    userStatusControllerUpdate: (id: string, data: UpdateUserStatusDto, params: RequestParams = {}) =>
      this.request<UpdateUserStatusResponseDto, any>({
        path: `/users/status/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserPersonalDetailsControllerFindCurrentUser
     * @request GET:/users/current-user/personal-details
     */
    userPersonalDetailsControllerFindCurrentUser: (params: RequestParams = {}) =>
      this.request<PersonalDetailDto, any>({
        path: `/users/current-user/personal-details`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserPersonalDetailsControllerFindOne
     * @request GET:/users/{userId}/personal-details
     */
    userPersonalDetailsControllerFindOne: (userId: string, params: RequestParams = {}) =>
      this.request<PersonalDetailDto, any>({
        path: `/users/${userId}/personal-details`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserPersonalDetailsControllerUpdate
     * @request PATCH:/users/personal-details/{id}
     */
    userPersonalDetailsControllerUpdate: (id: string, data: UpdatePersonalDetailDto, params: RequestParams = {}) =>
      this.request<PersonalDetailDto, any>({
        path: `/users/personal-details/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserPersonalDetailsControllerCreate
     * @request POST:/users/personal-details/create-address
     */
    userPersonalDetailsControllerCreate: (data: CreateAddressDto, params: RequestParams = {}) =>
      this.request<AddressDto, any>({
        path: `/users/personal-details/create-address`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserPersonalDetailsControllerUploadFile
     * @request POST:/users/personal-details/upload-personal-documents
     */
    userPersonalDetailsControllerUploadFile: (data: PersonalDocumentDto, params: RequestParams = {}) =>
      this.request<UploadedFileKeyDto, any>({
        path: `/users/personal-details/upload-personal-documents`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserPersonalDetailsControllerDeleteFile
     * @request DELETE:/users/personal-details/delete-personal-document
     */
    userPersonalDetailsControllerDeleteFile: (data: DeletePersonalDocumentDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/personal-details/delete-personal-document`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UserPersonalDetailsControllerCreateCitizenship
     * @request POST:/users/personal-details/citizenships
     */
    userPersonalDetailsControllerCreateCitizenship: (data: CreateUserCitizenshipDto, params: RequestParams = {}) =>
      this.request<UploadedFileKeyDto, any>({
        path: `/users/personal-details/citizenships`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserPersonalDetailsControllerRemoveCitizenship
     * @request DELETE:/users/personal-details/citizenships
     */
    userPersonalDetailsControllerRemoveCitizenship: (data: CreateUserCitizenshipDto, params: RequestParams = {}) =>
      this.request<UploadedFileKeyDto, any>({
        path: `/users/personal-details/citizenships`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserTravellingDetailsControllerFindCurrentUser
     * @request GET:/users/current-user/travelling-details
     */
    userTravellingDetailsControllerFindCurrentUser: (params: RequestParams = {}) =>
      this.request<TravellingDetailDto, any>({
        path: `/users/current-user/travelling-details`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserTravellingDetailsControllerFindOne
     * @request GET:/users/{userId}/travelling-details
     */
    userTravellingDetailsControllerFindOne: (userId: string, params: RequestParams = {}) =>
      this.request<TravellingDetailDto, any>({
        path: `/users/${userId}/travelling-details`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserTravellingDetailsControllerUpdate
     * @request PATCH:/users/travelling-details/{id}
     */
    userTravellingDetailsControllerUpdate: (id: string, data: UpdateTravellingDetailDto, params: RequestParams = {}) =>
      this.request<TravellingDetailDto, any>({
        path: `/users/travelling-details/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserTravellingDetailsControllerUploadFile
     * @request POST:/users/travelling-details/upload-passport
     */
    userTravellingDetailsControllerUploadFile: (data: AddPassportDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/travelling-details/upload-passport`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UserTravellingDetailsControllerDeletePassport
     * @request DELETE:/users/travelling-details/delete-passport
     */
    userTravellingDetailsControllerDeletePassport: (data: DeletePassportDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/travelling-details/delete-passport`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UserEmergencyContactsControllerCreate
     * @request POST:/users/emergency-contacts
     */
    userEmergencyContactsControllerCreate: (data: CreateEmergencyContactDto, params: RequestParams = {}) =>
      this.request<EmergencyContactDto, any>({
        path: `/users/emergency-contacts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserEmergencyContactsControllerFindManyCurrentUser
     * @request GET:/users/current-user/emergency-contacts
     */
    userEmergencyContactsControllerFindManyCurrentUser: (params: RequestParams = {}) =>
      this.request<EmergencyContactsDto, any>({
        path: `/users/current-user/emergency-contacts`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserEmergencyContactsControllerFindMany
     * @request GET:/users/{userId}/emergency-contacts
     */
    userEmergencyContactsControllerFindMany: (userId: string, params: RequestParams = {}) =>
      this.request<EmergencyContactsDto, any>({
        path: `/users/${userId}/emergency-contacts`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserEmergencyContactsControllerUpdate
     * @request PATCH:/users/emergency-contacts/{id}
     */
    userEmergencyContactsControllerUpdate: (id: string, data: UpdateEmergencyContactDto, params: RequestParams = {}) =>
      this.request<EmergencyContactDto, any>({
        path: `/users/emergency-contacts/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserEmergencyContactsControllerRemove
     * @request DELETE:/users/emergency-contacts/{id}
     */
    userEmergencyContactsControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/emergency-contacts/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @name AuthControllerLogin
     * @request POST:/auth/login
     */
    authControllerLogin: (data: AuthLoginDto, params: RequestParams = {}) =>
      this.request<AuthTokensResponseDto, any>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AuthControllerRegister
     * @request POST:/auth/register
     */
    authControllerRegister: (data: AuthRegisterDto, params: RequestParams = {}) =>
      this.request<RegisteredUserResponseDto, any>({
        path: `/auth/register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AuthControllerLogout
     * @request POST:/auth/logout
     */
    authControllerLogout: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/auth/logout`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AuthControllerRequestResetPassword
     * @request POST:/auth/request-reset-password
     */
    authControllerRequestResetPassword: (data: ResetPasswordDto, params: RequestParams = {}) =>
      this.request<ResetPasswordUrlDto, any>({
        path: `/auth/request-reset-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AuthControllerDeleteAccount
     * @request DELETE:/auth/delete-account
     */
    authControllerDeleteAccount: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/delete-account`,
        method: "DELETE",
        ...params,
      }),
  };
  workspaces = {
    /**
     * No description
     *
     * @name WorkspacesControllerFindAll
     * @request GET:/workspaces
     */
    workspacesControllerFindAll: (params: RequestParams = {}) =>
      this.request<WorkspacesDto, any>({
        path: `/workspaces`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name WorkspacesControllerFindOne
     * @request GET:/workspaces/{id}
     */
    workspacesControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<WorkspaceDto, any>({
        path: `/workspaces/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name WorkspacesControllerUpdate
     * @request PATCH:/workspaces/{id}
     */
    workspacesControllerUpdate: (id: string, data: UpdateWorkspaceDto, params: RequestParams = {}) =>
      this.request<WorkspaceDto, any>({
        path: `/workspaces/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name WorkspacesControllerFindAllArtists
     * @request GET:/workspaces/{id}/artists
     */
    workspacesControllerFindAllArtists: (id: string, params: RequestParams = {}) =>
      this.request<ArtistsDto, any>({
        path: `/workspaces/${id}/artists`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  artists = {
    /**
     * No description
     *
     * @name ArtistsControllerFindAll
     * @request GET:/artists
     */
    artistsControllerFindAll: (
      query?: {
        artistId?: string | null;
        archived?: boolean | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<ArtistsDto, any>({
        path: `/artists`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArtistsControllerCreate
     * @request POST:/artists
     */
    artistsControllerCreate: (data: CreateArtistDto, params: RequestParams = {}) =>
      this.request<ArtistDto, any>({
        path: `/artists`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArtistsControllerFindOne
     * @request GET:/artists/{id}
     */
    artistsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<ArtistDto, any>({
        path: `/artists/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArtistsControllerUpdate
     * @request PATCH:/artists/{id}
     */
    artistsControllerUpdate: (id: string, data: UpdateArtistDto, params: RequestParams = {}) =>
      this.request<ArtistDto, any>({
        path: `/artists/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArtistsControllerRemove
     * @request DELETE:/artists/{id}
     */
    artistsControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/artists/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArtistsControllerUploadFile
     * @request POST:/artists/{id}/upload-file
     */
    artistsControllerUploadFile: (id: string, data: UploadArtistFileDto, params: RequestParams = {}) =>
      this.request<UploadedFileKeyDto, any>({
        path: `/artists/${id}/upload-file`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ArtistsControllerDeleteFile
     * @request DELETE:/artists/{id}/delete-file
     */
    artistsControllerDeleteFile: (id: string, data: DeleteArtistFileDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/artists/${id}/delete-file`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ArtistsControllerArchive
     * @request PATCH:/artists/{id}/archive
     */
    artistsControllerArchive: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/artists/${id}/archive`,
        method: "PATCH",
        ...params,
      }),
  };
  tours = {
    /**
     * No description
     *
     * @name ToursControllerCreate
     * @request POST:/tours
     */
    toursControllerCreate: (data: CreateTourDto, params: RequestParams = {}) =>
      this.request<TourDto, any>({
        path: `/tours`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerFindAll
     * @request GET:/tours
     */
    toursControllerFindAll: (
      query?: {
        artistId?: string | null;
        archived?: boolean | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<ToursWithArtistDto, any>({
        path: `/tours`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerFindOne
     * @request GET:/tours/{id}
     */
    toursControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<TourWithRoleAndArtistDto, any>({
        path: `/tours/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerUpdate
     * @request PATCH:/tours/{id}
     */
    toursControllerUpdate: (id: string, data: UpdateTourDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tours/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerRemove
     * @request DELETE:/tours/{id}
     */
    toursControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tours/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerFindAllPersonnel
     * @request GET:/tours/{id}/personnel
     */
    toursControllerFindAllPersonnel: (id: string, params: RequestParams = {}) =>
      this.request<TourPersonnelArrayDto, any>({
        path: `/tours/${id}/personnel`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerCreatePersonnel
     * @request POST:/tours/{id}/personnel
     */
    toursControllerCreatePersonnel: (id: string, data: CreateTourPersonnelDto, params: RequestParams = {}) =>
      this.request<TourPersonnelDto, any>({
        path: `/tours/${id}/personnel`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerFindOnePersonnel
     * @request GET:/tours/{id}/personnel/{personnelId}
     */
    toursControllerFindOnePersonnel: (id: string, personnelId: string, params: RequestParams = {}) =>
      this.request<TourPersonnelDto, any>({
        path: `/tours/${id}/personnel/${personnelId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerRemovePersonnel
     * @request DELETE:/tours/{id}/personnel/{personnelId}
     */
    toursControllerRemovePersonnel: (id: string, personnelId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tours/${id}/personnel/${personnelId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerUpdatePersonnel
     * @request PATCH:/tours/{id}/personnel/{personnelId}
     */
    toursControllerUpdatePersonnel: (
      id: string,
      personnelId: string,
      data: UpdateTourPersonnelDto,
      params: RequestParams = {},
    ) =>
      this.request<TourPersonnelDto, any>({
        path: `/tours/${id}/personnel/${personnelId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerUploadFile
     * @request POST:/tours/{id}/upload-file
     */
    toursControllerUploadFile: (id: string, data: UploadTourFileDto, params: RequestParams = {}) =>
      this.request<UploadedFileKeyDto, any>({
        path: `/tours/${id}/upload-file`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerDeleteFile
     * @request DELETE:/tours/{id}/delete-file
     */
    toursControllerDeleteFile: (id: string, data: DeleteTourFileDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tours/${id}/delete-file`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerUploadPersonnelCsv
     * @request POST:/tours/{id}/upload-personnel-csv
     */
    toursControllerUploadPersonnelCsv: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tours/${id}/upload-personnel-csv`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @name ToursControllerArchive
     * @request PATCH:/tours/{id}/archive
     */
    toursControllerArchive: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tours/${id}/archive`,
        method: "PATCH",
        ...params,
      }),
  };
  tourPersonnelRoles = {
    /**
     * No description
     *
     * @name TourPersonnelRolesControllerCreate
     * @request POST:/tour-personnel-roles
     */
    tourPersonnelRolesControllerCreate: (data: CreateTourPersonnelRoleDto, params: RequestParams = {}) =>
      this.request<TourPersonnelRoleDto, any>({
        path: `/tour-personnel-roles`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TourPersonnelRolesControllerFindAll
     * @request GET:/tour-personnel-roles
     */
    tourPersonnelRolesControllerFindAll: (params: RequestParams = {}) =>
      this.request<TourPersonnelRolesDto, any>({
        path: `/tour-personnel-roles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TourPersonnelRolesControllerFindOne
     * @request GET:/tour-personnel-roles/{id}
     */
    tourPersonnelRolesControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<TourPersonnelRoleDto, any>({
        path: `/tour-personnel-roles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TourPersonnelRolesControllerUpdate
     * @request PATCH:/tour-personnel-roles/{id}
     */
    tourPersonnelRolesControllerUpdate: (id: string, data: UpdateTourPersonnelRoleDto, params: RequestParams = {}) =>
      this.request<TourPersonnelRoleDto, any>({
        path: `/tour-personnel-roles/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TourPersonnelRolesControllerRemove
     * @request DELETE:/tour-personnel-roles/{id}
     */
    tourPersonnelRolesControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tour-personnel-roles/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @name AdminControllerCreateUser
     * @request POST:/admin/users
     */
    adminControllerCreateUser: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<UserIdDto, any>({
        path: `/admin/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminControllerAllUsers
     * @request GET:/admin/users
     */
    adminControllerAllUsers: (params: RequestParams = {}) =>
      this.request<UsersDto, any>({
        path: `/admin/users`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminControllerRemoveUser
     * @request DELETE:/admin/users/{id}
     */
    adminControllerRemoveUser: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/users/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminControllerFindUser
     * @request GET:/admin/users/{id}
     */
    adminControllerFindUser: (id: string, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/admin/users/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminControllerAllWorkspaces
     * @request GET:/admin/workspaces
     */
    adminControllerAllWorkspaces: (params: RequestParams = {}) =>
      this.request<WorkspacesDto, any>({
        path: `/admin/workspaces`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminControllerCreate
     * @request POST:/admin/workspaces/{userId}
     */
    adminControllerCreate: (userId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/admin/workspaces/${userId}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminControllerAllTours
     * @request GET:/admin/tours
     */
    adminControllerAllTours: (params: RequestParams = {}) =>
      this.request<ToursDto, any>({
        path: `/admin/tours`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminControllerCreateEventType
     * @request POST:/admin/events/types
     */
    adminControllerCreateEventType: (data: CreateEventTypeDto, params: RequestParams = {}) =>
      this.request<EventTypeDto, any>({
        path: `/admin/events/types`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminControllerAllEventTypes
     * @request GET:/admin/events/types
     */
    adminControllerAllEventTypes: (params: RequestParams = {}) =>
      this.request<EventTypeDto, any>({
        path: `/admin/events/types`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminControllerUpdateEventType
     * @request PATCH:/admin/events/types/{id}
     */
    adminControllerUpdateEventType: (id: string, data: CreateEventTypeDto, params: RequestParams = {}) =>
      this.request<EventTypeDto, any>({
        path: `/admin/events/types/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AdminControllerAllEvents
     * @request GET:/admin/events
     */
    adminControllerAllEvents: (
      query?: {
        /** @format date-time */
        from?: string | null;
        /** @format date-time */
        to?: string | null;
        typeId?: string | null;
        /** Array of UUIDs */
        artistIds?: any[] | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<EventsDto, any>({
        path: `/admin/events`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  upload = {
    /**
     * No description
     *
     * @name UploadControllerUploadFile
     * @request POST:/upload
     */
    uploadControllerUploadFile: (params: RequestParams = {}) =>
      this.request<UploadedFileKeyDto, any>({
        path: `/upload`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UploadControllerRemove
     * @request DELETE:/upload/delete
     */
    uploadControllerRemove: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/upload/delete`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name UploadControllerGetDownloadUrl
     * @request POST:/upload/download-url
     */
    uploadControllerGetDownloadUrl: (data: FileKeyDto, params: RequestParams = {}) =>
      this.request<UploadedFileDto, any>({
        path: `/upload/download-url`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  events = {
    /**
     * No description
     *
     * @name EventsControllerDuplicate
     * @request POST:/events/duplicate
     */
    eventsControllerDuplicate: (data: DuplicateEventDto, params: RequestParams = {}) =>
      this.request<EventDto[], any>({
        path: `/events/duplicate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerCreate
     * @request POST:/events/{tourId}
     */
    eventsControllerCreate: (tourId: string, data: CreateEventDto, params: RequestParams = {}) =>
      this.request<EventDto, any>({
        path: `/events/${tourId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerFindAllByTour
     * @request GET:/events/tour/{tourId}
     */
    eventsControllerFindAllByTour: (
      tourId: string,
      query?: {
        /** @format date-time */
        from?: string | null;
        /** @format date-time */
        to?: string | null;
        typeId?: string | null;
        /** Array of UUIDs */
        artistIds?: any[] | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<EventsDto, any>({
        path: `/events/tour/${tourId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerFindUpcoming
     * @request GET:/events/upcoming
     */
    eventsControllerFindUpcoming: (
      query?: {
        /** @format date-time */
        from?: string | null;
        /** @format date-time */
        to?: string | null;
        typeId?: string | null;
        /** Array of UUIDs */
        artistIds?: any[] | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<EventsDto, any>({
        path: `/events/upcoming`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerFindAll
     * @request GET:/events/types
     */
    eventsControllerFindAll: (params: RequestParams = {}) =>
      this.request<EventTypesDto, any>({
        path: `/events/types`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerFindOne
     * @request GET:/events/{id}
     */
    eventsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<EventDto, any>({
        path: `/events/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerUpdate
     * @request PATCH:/events/{id}
     */
    eventsControllerUpdate: (id: string, data: UpdateEventDto, params: RequestParams = {}) =>
      this.request<EventDto, any>({
        path: `/events/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerRemove
     * @request DELETE:/events/{id}
     */
    eventsControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/events/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerCreatePersonnel
     * @request POST:/events/{id}/personnel
     */
    eventsControllerCreatePersonnel: (id: string, data: CreateEventPersonnelDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/events/${id}/personnel`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerFindAllPersonnel
     * @request GET:/events/{id}/personnel
     */
    eventsControllerFindAllPersonnel: (id: string, params: RequestParams = {}) =>
      this.request<EventPersonnelArrayDto, any>({
        path: `/events/${id}/personnel`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerCreatePersonnelGroup
     * @request POST:/events/{id}/personnel-groups
     */
    eventsControllerCreatePersonnelGroup: (
      id: string,
      data: CreateEventPersonnelGroupDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/events/${id}/personnel-groups`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerFindOnePersonnel
     * @request GET:/events/{id}/personnel/{personnelId}
     */
    eventsControllerFindOnePersonnel: (id: string, personnelId: string, params: RequestParams = {}) =>
      this.request<EventPersonnelDto, any>({
        path: `/events/${id}/personnel/${personnelId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerRemovePersonnel
     * @request DELETE:/events/{id}/personnel/{personnelId}
     */
    eventsControllerRemovePersonnel: (id: string, personnelId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/events/${id}/personnel/${personnelId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventsControllerUpdatePersonnel
     * @request PATCH:/events/{id}/personnel/{personnelId}
     */
    eventsControllerUpdatePersonnel: (
      id: string,
      personnelId: string,
      data: UpdateEventPersonnelDto,
      params: RequestParams = {},
    ) =>
      this.request<EventPersonnelDto, any>({
        path: `/events/${id}/personnel/${personnelId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  eventProviders = {
    /**
     * No description
     *
     * @name EventProvidersControllerCreate
     * @request POST:/event-providers
     */
    eventProvidersControllerCreate: (data: CreateEventProviderDto, params: RequestParams = {}) =>
      this.request<EventProviderDto, any>({
        path: `/event-providers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventProvidersControllerUpdate
     * @request PATCH:/event-providers/{id}
     */
    eventProvidersControllerUpdate: (id: string, data: UpdateEventProviderDto, params: RequestParams = {}) =>
      this.request<EventProviderDto, any>({
        path: `/event-providers/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventProvidersControllerRemove
     * @request DELETE:/event-providers/{id}
     */
    eventProvidersControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/event-providers/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventProvidersControllerFindOne
     * @request GET:/event-providers/{id}
     */
    eventProvidersControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<EventProviderDto, any>({
        path: `/event-providers/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventProvidersControllerFindAll
     * @request GET:/event-providers/workspace/{workspaceId}
     */
    eventProvidersControllerFindAll: (workspaceId: string, params: RequestParams = {}) =>
      this.request<EventProvidersDto, any>({
        path: `/event-providers/workspace/${workspaceId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  dayTags = {
    /**
     * No description
     *
     * @name DayTagsControllerFindAll
     * @request GET:/day-tags/{workspaceId}
     */
    dayTagsControllerFindAll: (workspaceId: string, params: RequestParams = {}) =>
      this.request<DayTagsDto, any>({
        path: `/day-tags/${workspaceId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DayTagsControllerCreate
     * @request POST:/day-tags
     */
    dayTagsControllerCreate: (data: CreateDayTagDto, params: RequestParams = {}) =>
      this.request<DayTagDto, any>({
        path: `/day-tags`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DayTagsControllerRemove
     * @request DELETE:/day-tags/{id}
     */
    dayTagsControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/day-tags/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  tourDays = {
    /**
     * No description
     *
     * @name TourDaysControllerFindAll
     * @request GET:/tour-days/{tourId}
     */
    tourDaysControllerFindAll: (tourId: string, params: RequestParams = {}) =>
      this.request<TourDaysDto, any>({
        path: `/tour-days/${tourId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TourDaysControllerCreate
     * @request POST:/tour-days/{tourId}
     */
    tourDaysControllerCreate: (tourId: string, data: CreateTourDayDto, params: RequestParams = {}) =>
      this.request<CreatedTourDayDto, any>({
        path: `/tour-days/${tourId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TourDaysControllerCreateNote
     * @request POST:/tour-days/{tourId}/notes
     */
    tourDaysControllerCreateNote: (tourId: string, data: CreateTourDayNoteDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tour-days/${tourId}/notes`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name TourDaysControllerUpdateNote
     * @request PATCH:/tour-days/{tourId}/notes/{id}
     */
    tourDaysControllerUpdateNote: (
      tourId: string,
      id: string,
      data: UpdateTourDayNoteDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/tour-days/${tourId}/notes/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name TourDaysControllerRemoveNote
     * @request DELETE:/tour-days/{tourId}/notes/{id}
     */
    tourDaysControllerRemoveNote: (tourId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tour-days/${tourId}/notes/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  eventPersonnelRoles = {
    /**
     * No description
     *
     * @name EventPersonnelRolesControllerFindAll
     * @request GET:/event-personnel-roles
     */
    eventPersonnelRolesControllerFindAll: (params: RequestParams = {}) =>
      this.request<EventPersonnelRolesDto, any>({
        path: `/event-personnel-roles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name EventPersonnelRolesControllerFindOne
     * @request GET:/event-personnel-roles/{id}
     */
    eventPersonnelRolesControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<EventPersonnelRoleDto, any>({
        path: `/event-personnel-roles/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  tableColumns = {
    /**
     * No description
     *
     * @name TableColumnsControllerFindAll
     * @request GET:/table-columns
     */
    tableColumnsControllerFindAll: (params: RequestParams = {}) =>
      this.request<TableColumnsDto, any>({
        path: `/table-columns`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TableColumnsControllerFindSupplementalQuestions
     * @request GET:/table-columns/supplemental-questions
     */
    tableColumnsControllerFindSupplementalQuestions: (params: RequestParams = {}) =>
      this.request<TableColumnsSupplementalQuestionsDto, any>({
        path: `/table-columns/supplemental-questions`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  reportTemplates = {
    /**
     * No description
     *
     * @name ReportTemplatesControllerCreate
     * @request POST:/report-templates
     */
    reportTemplatesControllerCreate: (data: CreateReportTemplateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/report-templates`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportTemplatesControllerFindAll
     * @request GET:/report-templates/{workspaceId}
     */
    reportTemplatesControllerFindAll: (workspaceId: string, params: RequestParams = {}) =>
      this.request<ReportTemplatesDto, any>({
        path: `/report-templates/${workspaceId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportTemplatesControllerFindReports
     * @request GET:/report-templates/{id}/reports
     */
    reportTemplatesControllerFindReports: (id: string, params: RequestParams = {}) =>
      this.request<ReportTemplatesDto, any>({
        path: `/report-templates/${id}/reports`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  reports = {
    /**
     * No description
     *
     * @name ReportsControllerCreate
     * @request POST:/reports
     */
    reportsControllerCreate: (data: CreateReportDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reports`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportsControllerFindAllByTour
     * @request GET:/reports
     */
    reportsControllerFindAllByTour: (
      query?: {
        reportTemplateId?: string | null;
        tourId?: string | null;
        workspaceId?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReportsDto, any>({
        path: `/reports`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReportsControllerFindOne
     * @request GET:/reports/{id}
     */
    reportsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reports/${id}`,
        method: "GET",
        ...params,
      }),
  };
  groups = {
    /**
     * No description
     *
     * @name GroupsControllerCreate
     * @request POST:/groups/{tourId}
     */
    groupsControllerCreate: (tourId: string, data: CreateGroupDto, params: RequestParams = {}) =>
      this.request<GroupDto, any>({
        path: `/groups/${tourId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupsControllerFindAllByTour
     * @request GET:/groups/tour/{tourId}
     */
    groupsControllerFindAllByTour: (tourId: string, params: RequestParams = {}) =>
      this.request<GroupsDto, any>({
        path: `/groups/tour/${tourId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupsControllerFindOne
     * @request GET:/groups/{id}
     */
    groupsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<GroupDto, any>({
        path: `/groups/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupsControllerUpdate
     * @request PATCH:/groups/{id}
     */
    groupsControllerUpdate: (id: string, data: UpdateGroupDto, params: RequestParams = {}) =>
      this.request<GroupDto, any>({
        path: `/groups/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupsControllerRemove
     * @request DELETE:/groups/{id}
     */
    groupsControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/groups/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupsControllerCreatePersonnel
     * @request POST:/groups/{id}/members
     */
    groupsControllerCreatePersonnel: (id: string, data: CreateGroupMembersDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/groups/${id}/members`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupsControllerFindAllPersonnel
     * @request GET:/groups/{id}/members
     */
    groupsControllerFindAllPersonnel: (id: string, params: RequestParams = {}) =>
      this.request<GroupMembersArrayDto, any>({
        path: `/groups/${id}/members`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupsControllerFindOnePersonnel
     * @request GET:/groups/{id}/members/{memberId}
     */
    groupsControllerFindOnePersonnel: (id: string, memberId: string, params: RequestParams = {}) =>
      this.request<GroupMemberDto, any>({
        path: `/groups/${id}/members/${memberId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GroupsControllerRemovePersonnel
     * @request DELETE:/groups/{id}/members/{memberId}
     */
    groupsControllerRemovePersonnel: (id: string, memberId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/groups/${id}/members/${memberId}`,
        method: "DELETE",
        ...params,
      }),
  };
  reportFiles = {
    /**
     * No description
     *
     * @name ReportFilesControllerDownloadReportFiles
     * @request GET:/report-files/download/{reportId}
     */
    reportFilesControllerDownloadReportFiles: (reportId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/report-files/download/${reportId}`,
        method: "GET",
        ...params,
      }),
  };
  riders = {
    /**
     * No description
     *
     * @name RidersControllerFindAllTypes
     * @request GET:/riders/types
     */
    ridersControllerFindAllTypes: (params: RequestParams = {}) =>
      this.request<RiderTypesDto, any>({
        path: `/riders/types`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerCreate
     * @request POST:/riders/tour/{tourId}
     */
    ridersControllerCreate: (tourId: string, data: CreateRiderDto, params: RequestParams = {}) =>
      this.request<RiderDto, any>({
        path: `/riders/tour/${tourId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerFindAllByTour
     * @request GET:/riders/tour/{tourId}
     */
    ridersControllerFindAllByTour: (tourId: string, params: RequestParams = {}) =>
      this.request<RidersDto, any>({
        path: `/riders/tour/${tourId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerFindOne
     * @request GET:/riders/{id}
     */
    ridersControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<RiderDto, any>({
        path: `/riders/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerUpdate
     * @request PATCH:/riders/{id}
     */
    ridersControllerUpdate: (id: string, data: UpdateRiderDto, params: RequestParams = {}) =>
      this.request<RiderDto, any>({
        path: `/riders/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerRemove
     * @request DELETE:/riders/{id}
     */
    ridersControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/riders/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerCreatePersonnel
     * @request POST:/riders/{id}/personnel
     */
    ridersControllerCreatePersonnel: (id: string, data: CreateRiderPersonnelDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/riders/${id}/personnel`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerFindAllPersonnel
     * @request GET:/riders/{id}/personnel
     */
    ridersControllerFindAllPersonnel: (id: string, params: RequestParams = {}) =>
      this.request<RiderPersonnelArrayDto, any>({
        path: `/riders/${id}/personnel`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerFindOnePersonnel
     * @request GET:/riders/{id}/personnel/{personnelId}
     */
    ridersControllerFindOnePersonnel: (id: string, personnelId: string, params: RequestParams = {}) =>
      this.request<RiderPersonnelDto, any>({
        path: `/riders/${id}/personnel/${personnelId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerRemovePersonnel
     * @request DELETE:/riders/{id}/personnel/{personnelId}
     */
    ridersControllerRemovePersonnel: (id: string, personnelId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/riders/${id}/personnel/${personnelId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerCreateExternalPersonnel
     * @request POST:/riders/{id}/external-personnel
     */
    ridersControllerCreateExternalPersonnel: (
      id: string,
      data: CreateExternalRiderPersonnelDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/riders/${id}/external-personnel`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerFindAllExternalPersonnel
     * @request GET:/riders/{id}/external-personnel
     */
    ridersControllerFindAllExternalPersonnel: (id: string, params: RequestParams = {}) =>
      this.request<ExternalRiderPersonnelArrayDto, any>({
        path: `/riders/${id}/external-personnel`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerFindOneExternalPersonnel
     * @request GET:/riders/{id}/external-personnel/{personnelId}
     */
    ridersControllerFindOneExternalPersonnel: (personnelId: string, id: string, params: RequestParams = {}) =>
      this.request<RiderPersonnelDto, any>({
        path: `/riders/${id}/external-personnel/${personnelId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerUpdatePersonnel
     * @request PATCH:/riders/{id}/external-personnel/{personnelId}
     */
    ridersControllerUpdatePersonnel: (
      personnelId: string,
      id: string,
      data: UpdateExternalRiderPersonnelDto,
      params: RequestParams = {},
    ) =>
      this.request<ExternalRiderPersonnelDto, any>({
        path: `/riders/${id}/external-personnel/${personnelId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerRemoveExternalPersonnel
     * @request DELETE:/riders/{id}/external-personnel/{personnelId}
     */
    ridersControllerRemoveExternalPersonnel: (personnelId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/riders/${id}/external-personnel/${personnelId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerUploadFile
     * @request POST:/riders/{id}/upload-file
     */
    ridersControllerUploadFile: (id: string, data: UploadRiderFileDto, params: RequestParams = {}) =>
      this.request<UploadedFileKeyDto, any>({
        path: `/riders/${id}/upload-file`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RidersControllerDeleteFile
     * @request DELETE:/riders/{id}/delete-file
     */
    ridersControllerDeleteFile: (id: string, data: DeleteRiderFileDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/riders/${id}/delete-file`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  riderLabors = {
    /**
     * No description
     *
     * @name RiderLaborsControllerCreate
     * @request POST:/rider-labors
     */
    riderLaborsControllerCreate: (data: CreateRiderLaborDto, params: RequestParams = {}) =>
      this.request<RiderLaborDto, any>({
        path: `/rider-labors`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderLaborsControllerFindAllByRiderLabor
     * @request GET:/rider-labors/rider/{riderId}
     */
    riderLaborsControllerFindAllByRiderLabor: (riderId: string, params: RequestParams = {}) =>
      this.request<RiderLaborsDto, any>({
        path: `/rider-labors/rider/${riderId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderLaborsControllerFindOne
     * @request GET:/rider-labors/{id}
     */
    riderLaborsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<RiderLaborDto, any>({
        path: `/rider-labors/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderLaborsControllerUpdate
     * @request PATCH:/rider-labors/{id}
     */
    riderLaborsControllerUpdate: (id: string, data: UpdateRiderLaborDto, params: RequestParams = {}) =>
      this.request<RiderLaborDto, any>({
        path: `/rider-labors/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderLaborsControllerRemove
     * @request DELETE:/rider-labors/{id}
     */
    riderLaborsControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rider-labors/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  riderSupportingActs = {
    /**
     * No description
     *
     * @name RiderSupportingActsControllerCreate
     * @request POST:/rider-supporting-acts
     */
    riderSupportingActsControllerCreate: (data: CreateRiderSupportingActDto, params: RequestParams = {}) =>
      this.request<RiderSupportingActDto, any>({
        path: `/rider-supporting-acts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderSupportingActsControllerFindAllByRider
     * @request GET:/rider-supporting-acts/rider/{riderId}
     */
    riderSupportingActsControllerFindAllByRider: (riderId: string, params: RequestParams = {}) =>
      this.request<RiderSupportingActsDto, any>({
        path: `/rider-supporting-acts/rider/${riderId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderSupportingActsControllerFindOne
     * @request GET:/rider-supporting-acts/{id}
     */
    riderSupportingActsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<RiderSupportingActDto, any>({
        path: `/rider-supporting-acts/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderSupportingActsControllerUpdate
     * @request PATCH:/rider-supporting-acts/{id}
     */
    riderSupportingActsControllerUpdate: (id: string, data: UpdateRiderSupportingActDto, params: RequestParams = {}) =>
      this.request<RiderSupportingActDto, any>({
        path: `/rider-supporting-acts/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderSupportingActsControllerRemove
     * @request DELETE:/rider-supporting-acts/{id}
     */
    riderSupportingActsControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rider-supporting-acts/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderSupportingActsControllerUploadFile
     * @request POST:/rider-supporting-acts/{id}/upload-file
     */
    riderSupportingActsControllerUploadFile: (
      id: string,
      data: UploadRiderSupportingActFileDto,
      params: RequestParams = {},
    ) =>
      this.request<UploadedFileKeyDto, any>({
        path: `/rider-supporting-acts/${id}/upload-file`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderSupportingActsControllerDeleteFile
     * @request DELETE:/rider-supporting-acts/{id}/delete-file
     */
    riderSupportingActsControllerDeleteFile: (
      id: string,
      data: DeleteRiderSupportingActFileDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rider-supporting-acts/${id}/delete-file`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderSupportingActsControllerCreateSupportingActPersonnel
     * @request POST:/rider-supporting-acts/{id}/personnel
     */
    riderSupportingActsControllerCreateSupportingActPersonnel: (
      id: string,
      data: CreateSupportingActPersonnelDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rider-supporting-acts/${id}/personnel`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderSupportingActsControllerFindAllSupportingActPersonnel
     * @request GET:/rider-supporting-acts/{id}/personnel
     */
    riderSupportingActsControllerFindAllSupportingActPersonnel: (id: string, params: RequestParams = {}) =>
      this.request<SupportingActPersonnelArrayDto, any>({
        path: `/rider-supporting-acts/${id}/personnel`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderSupportingActsControllerFindOneSupportingActPersonnel
     * @request GET:/rider-supporting-acts/{id}/personnel/{personnelId}
     */
    riderSupportingActsControllerFindOneSupportingActPersonnel: (
      personnelId: string,
      id: string,
      params: RequestParams = {},
    ) =>
      this.request<SupportingActPersonnelDto, any>({
        path: `/rider-supporting-acts/${id}/personnel/${personnelId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderSupportingActsControllerUpdatePersonnel
     * @request PATCH:/rider-supporting-acts/{id}/personnel/{personnelId}
     */
    riderSupportingActsControllerUpdatePersonnel: (
      personnelId: string,
      id: string,
      data: UpdateSupportingActPersonnelDto,
      params: RequestParams = {},
    ) =>
      this.request<SupportingActPersonnelDto, any>({
        path: `/rider-supporting-acts/${id}/personnel/${personnelId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderSupportingActsControllerRemoveSupportingActPersonnel
     * @request DELETE:/rider-supporting-acts/{id}/personnel/{personnelId}
     */
    riderSupportingActsControllerRemoveSupportingActPersonnel: (
      personnelId: string,
      id: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rider-supporting-acts/${id}/personnel/${personnelId}`,
        method: "DELETE",
        ...params,
      }),
  };
  personalEvents = {
    /**
     * No description
     *
     * @name PersonalEventsControllerCreate
     * @request POST:/personal-events
     */
    personalEventsControllerCreate: (data: CreatePersonalEventDto, params: RequestParams = {}) =>
      this.request<PersonalEventDto, any>({
        path: `/personal-events`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PersonalEventsControllerFindAll
     * @request GET:/personal-events
     */
    personalEventsControllerFindAll: (params: RequestParams = {}) =>
      this.request<PersonalEventsDto, any>({
        path: `/personal-events`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PersonalEventsControllerFindOne
     * @request GET:/personal-events/{id}
     */
    personalEventsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<PersonalEventDto, any>({
        path: `/personal-events/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PersonalEventsControllerUpdate
     * @request PATCH:/personal-events/{id}
     */
    personalEventsControllerUpdate: (id: string, data: UpdatePersonalEventDto, params: RequestParams = {}) =>
      this.request<PersonalEventDto, any>({
        path: `/personal-events/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PersonalEventsControllerRemove
     * @request DELETE:/personal-events/{id}
     */
    personalEventsControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/personal-events/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  chatRooms = {
    /**
     * No description
     *
     * @name ChatRoomsControllerCreate
     * @request POST:/chat-rooms
     */
    chatRoomsControllerCreate: (data: CreateChatRoomDto, params: RequestParams = {}) =>
      this.request<ChatRoomDto, any>({
        path: `/chat-rooms`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChatRoomsControllerFindAll
     * @request GET:/chat-rooms
     */
    chatRoomsControllerFindAll: (params: RequestParams = {}) =>
      this.request<ChatRoomsDto, any>({
        path: `/chat-rooms`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChatRoomsControllerFindUnreadMessages
     * @request GET:/chat-rooms/unread-messages
     */
    chatRoomsControllerFindUnreadMessages: (params: RequestParams = {}) =>
      this.request<UnreadMessagesDto, any>({
        path: `/chat-rooms/unread-messages`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChatRoomsControllerFindOne
     * @request GET:/chat-rooms/{id}
     */
    chatRoomsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<ChatRoomDto, any>({
        path: `/chat-rooms/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChatRoomsControllerUpdate
     * @request PATCH:/chat-rooms/{id}
     */
    chatRoomsControllerUpdate: (id: string, data: UpdateChatRoomDto, params: RequestParams = {}) =>
      this.request<ChatRoomDto, any>({
        path: `/chat-rooms/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChatRoomsControllerArchive
     * @request PATCH:/chat-rooms/{id}/archive
     */
    chatRoomsControllerArchive: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/chat-rooms/${id}/archive`,
        method: "PATCH",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChatRoomsControllerVisit
     * @request PATCH:/chat-rooms/{id}/visit
     */
    chatRoomsControllerVisit: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/chat-rooms/${id}/visit`,
        method: "PATCH",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChatRoomsControllerCreatePersonnel
     * @request POST:/chat-rooms/{id}/members
     */
    chatRoomsControllerCreatePersonnel: (id: string, data: CreateChatRoomMembersDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/chat-rooms/${id}/members`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ChatRoomsControllerRemovePersonnel
     * @request DELETE:/chat-rooms/{id}/members/{memberId}
     */
    chatRoomsControllerRemovePersonnel: (memberId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/chat-rooms/${id}/members/${memberId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChatRoomsControllerSendMessage
     * @request POST:/chat-rooms/{id}/messages
     */
    chatRoomsControllerSendMessage: (id: string, data: CreateMessageDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/chat-rooms/${id}/messages`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ChatRoomsControllerFindMessages
     * @request GET:/chat-rooms/{id}/messages
     */
    chatRoomsControllerFindMessages: (id: string, params: RequestParams = {}) =>
      this.request<MessagesDto, any>({
        path: `/chat-rooms/${id}/messages`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  accommodationRooms = {
    /**
     * No description
     *
     * @name AccommodationRoomsControllerCreate
     * @request POST:/accommodation-rooms/{eventId}
     */
    accommodationRoomsControllerCreate: (
      eventId: string,
      data: CreateAccommodationRoomDto,
      params: RequestParams = {},
    ) =>
      this.request<AccommodationRoomDto, any>({
        path: `/accommodation-rooms/${eventId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AccommodationRoomsControllerFindAll
     * @request GET:/accommodation-rooms/events/{eventId}
     */
    accommodationRoomsControllerFindAll: (eventId: string, params: RequestParams = {}) =>
      this.request<AccommodationRoomsDto, any>({
        path: `/accommodation-rooms/events/${eventId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AccommodationRoomsControllerFindOne
     * @request GET:/accommodation-rooms/{id}
     */
    accommodationRoomsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<AccommodationRoomDto, any>({
        path: `/accommodation-rooms/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AccommodationRoomsControllerUpdate
     * @request PATCH:/accommodation-rooms/{id}
     */
    accommodationRoomsControllerUpdate: (id: string, data: UpdateAccommodationRoomDto, params: RequestParams = {}) =>
      this.request<AccommodationRoomDto, any>({
        path: `/accommodation-rooms/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AccommodationRoomsControllerRemove
     * @request DELETE:/accommodation-rooms/{id}
     */
    accommodationRoomsControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/accommodation-rooms/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name AccommodationRoomsControllerCreatePersonnel
     * @request POST:/accommodation-rooms/{id}/members
     */
    accommodationRoomsControllerCreatePersonnel: (id: string, data: CreateRoomMembersDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/accommodation-rooms/${id}/members`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AccommodationRoomsControllerRemovePersonnel
     * @request DELETE:/accommodation-rooms/{id}/members/{memberId}
     */
    accommodationRoomsControllerRemovePersonnel: (memberId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/accommodation-rooms/${id}/members/${memberId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name AccommodationRoomsControllerUpdatePersonnel
     * @request PATCH:/accommodation-rooms/{id}/members/{memberId}
     */
    accommodationRoomsControllerUpdatePersonnel: (
      memberId: string,
      id: string,
      data: UpdateRoomMemberDto,
      params: RequestParams = {},
    ) =>
      this.request<RoomMemberDto, any>({
        path: `/accommodation-rooms/${id}/members/${memberId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  tourFiles = {
    /**
     * No description
     *
     * @name TourFilesControllerUploadFile
     * @request POST:/tour-files/{tourId}
     */
    tourFilesControllerUploadFile: (tourId: string, params: RequestParams = {}) =>
      this.request<CreatedTourFileDto, any>({
        path: `/tour-files/${tourId}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TourFilesControllerFindAllByTour
     * @request GET:/tour-files
     */
    tourFilesControllerFindAllByTour: (
      query?: {
        tourId?: string | null;
        artistId?: string | null;
        sortOrder?: "asc" | "desc";
        sortBy?: "createdAt" | "name";
      },
      params: RequestParams = {},
    ) =>
      this.request<TourFilesDto, any>({
        path: `/tour-files`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TourFilesControllerDeleteFile
     * @request DELETE:/tour-files/{id}
     */
    tourFilesControllerDeleteFile: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tour-files/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @name FilesControllerDeleteFile
     * @request DELETE:/files/{id}
     */
    filesControllerDeleteFile: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  supplementalAnswers = {
    /**
     * No description
     *
     * @name SupplementalAnswersControllerCreate
     * @request POST:/supplemental-answers
     */
    supplementalAnswersControllerCreate: (data: CreateSupplementalAnswerDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/supplemental-answers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SupplementalAnswersControllerUpdate
     * @request PATCH:/supplemental-answers/{id}
     */
    supplementalAnswersControllerUpdate: (id: string, data: UpdateSupplementalAnswerDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/supplemental-answers/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SupplementalAnswersControllerFindOne
     * @request GET:/supplemental-answers/{id}
     */
    supplementalAnswersControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<SupplementalAnswerDto, any>({
        path: `/supplemental-answers/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SupplementalAnswersControllerRemove
     * @request DELETE:/supplemental-answers/{id}
     */
    supplementalAnswersControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/supplemental-answers/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name SupplementalAnswersControllerFindForUser
     * @request GET:/supplemental-answers/users/{userId}
     */
    supplementalAnswersControllerFindForUser: (userId: string, params: RequestParams = {}) =>
      this.request<SupplementalAnswersDto, any>({
        path: `/supplemental-answers/users/${userId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  supplementalQuestions = {
    /**
     * No description
     *
     * @name SupplementalQuestionsControllerFindAllForCountry
     * @request GET:/supplemental-questions
     */
    supplementalQuestionsControllerFindAllForCountry: (
      query?: {
        country?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SupplementalQuestionsDto, any>({
        path: `/supplemental-questions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SupplementalQuestionsControllerFindAllForTour
     * @request GET:/supplemental-questions/{tourId}
     */
    supplementalQuestionsControllerFindAllForTour: (tourId: string, params: RequestParams = {}) =>
      this.request<SupplementalQuestionsDto, any>({
        path: `/supplemental-questions/${tourId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SupplementalQuestionsControllerFindUnanswered
     * @request GET:/supplemental-questions/unanswered/{tourId}
     */
    supplementalQuestionsControllerFindUnanswered: (tourId: string, params: RequestParams = {}) =>
      this.request<SupplementalQuestionsDto, any>({
        path: `/supplemental-questions/unanswered/${tourId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  dayFiles = {
    /**
     * No description
     *
     * @name DayFilesControllerCreate
     * @request POST:/day-files
     */
    dayFilesControllerCreate: (data: CreateDayFileDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/day-files`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DayFilesControllerFindAll
     * @request GET:/day-files/{tourDayId}
     */
    dayFilesControllerFindAll: (tourDayId: string, params: RequestParams = {}) =>
      this.request<DayFilesDto, any>({
        path: `/day-files/${tourDayId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DayFilesControllerDelete
     * @request DELETE:/day-files/{id}
     */
    dayFilesControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/day-files/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  riderLaborTypes = {
    /**
     * No description
     *
     * @name RiderLaborTypesControllerCreate
     * @request POST:/rider-labor-types
     */
    riderLaborTypesControllerCreate: (data: CreateRiderLaborTypeDto, params: RequestParams = {}) =>
      this.request<RiderLaborTypeDto, any>({
        path: `/rider-labor-types`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderLaborTypesControllerFindAll
     * @request GET:/rider-labor-types/{tourId}
     */
    riderLaborTypesControllerFindAll: (tourId: string, params: RequestParams = {}) =>
      this.request<RiderLaborTypesDto, any>({
        path: `/rider-labor-types/${tourId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RiderLaborTypesControllerRemove
     * @request DELETE:/rider-labor-types/{id}
     */
    riderLaborTypesControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rider-labor-types/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  productionTrackers = {
    /**
     * No description
     *
     * @name ProductionTrackersControllerCreate
     * @request POST:/production-trackers
     */
    productionTrackersControllerCreate: (data: CreateProductionTrackerDto, params: RequestParams = {}) =>
      this.request<ProductionTrackerDto[], any>({
        path: `/production-trackers`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ProductionTrackersControllerFindOne
     * @request GET:/production-trackers
     */
    productionTrackersControllerFindOne: (
      query?: {
        eventId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductionTrackerDto, any>({
        path: `/production-trackers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ProductionTrackersControllerUpdate
     * @request PATCH:/production-trackers/{id}
     */
    productionTrackersControllerUpdate: (id: string, data: UpdateProductionTrackerDto, params: RequestParams = {}) =>
      this.request<ProductionTrackerDto, any>({
        path: `/production-trackers/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ProductionTrackersControllerDelete
     * @request DELETE:/production-trackers/{id}
     */
    productionTrackersControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/production-trackers/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  flights = {
    /**
     * No description
     *
     * @name FlightsControllerGetFlights
     * @request GET:/flights
     */
    flightsControllerGetFlights: (
      query: {
        startDate: string;
        endDate: string;
        airline: string;
        flightNumber: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FlightsInfoDto, any>({
        path: `/flights`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  passportExpiration = {
    /**
     * No description
     *
     * @name PassportExpirationControllerFindOne
     * @request GET:/passport-expiration/{tourId}
     */
    passportExpirationControllerFindOne: (tourId: string, params: RequestParams = {}) =>
      this.request<ExpiringPassportsDto, any>({
        path: `/passport-expiration/${tourId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  notifications = {
    /**
     * No description
     *
     * @name NotificationsControllerSendNotification
     * @request POST:/notifications
     */
    notificationsControllerSendNotification: (data: SendNotificationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name NotificationsControllerCreateMobileDevice
     * @request POST:/notifications/mobile-device
     */
    notificationsControllerCreateMobileDevice: (data: CreateMobileDeviceDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/mobile-device`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  googleApi = {
    /**
     * No description
     *
     * @name GoogleApiControllerSearchPlaces
     * @summary Search Places
     * @request POST:/google-api/places
     */
    googleApiControllerSearchPlaces: (data: QueryPlacesDto, params: RequestParams = {}) =>
      this.request<GooglePlacesDto, any>({
        path: `/google-api/places`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GoogleApiControllerGetTimezone
     * @summary Get Timezone
     * @request GET:/google-api/timezone
     */
    googleApiControllerGetTimezone: (
      query: {
        latitude: string;
        longitude: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GoogleTimezoneDto, any>({
        path: `/google-api/timezone`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  stripe = {
    /**
     * No description
     *
     * @name StripeControllerListPrices
     * @request GET:/stripe/prices
     */
    stripeControllerListPrices: (params: RequestParams = {}) =>
      this.request<PricesDto, any>({
        path: `/stripe/prices`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StripeControllerCreateSession
     * @request POST:/stripe/checkout-session
     */
    stripeControllerCreateSession: (data: CreateCheckoutSessionDto, params: RequestParams = {}) =>
      this.request<CheckoutUrlDto, any>({
        path: `/stripe/checkout-session`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StripeControllerPaymentSucceeded
     * @request POST:/stripe/webhooks/invoice-payment-succeeded
     */
    stripeControllerPaymentSucceeded: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stripe/webhooks/invoice-payment-succeeded`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @name StripeControllerSubscriptionUpdated
     * @request POST:/stripe/webhooks/customer-subscription-updated
     */
    stripeControllerSubscriptionUpdated: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stripe/webhooks/customer-subscription-updated`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @name StripeControllerSubscriptionDeleted
     * @request POST:/stripe/webhooks/customer-subscription-deleted
     */
    stripeControllerSubscriptionDeleted: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stripe/webhooks/customer-subscription-deleted`,
        method: "POST",
        ...params,
      }),
  };
  stripeSubscriptions = {
    /**
     * No description
     *
     * @name StripeSubscriptionsControllerFindUserSubscription
     * @request GET:/stripe-subscriptions/{userId}
     */
    stripeSubscriptionsControllerFindUserSubscription: (userId: string, params: RequestParams = {}) =>
      this.request<StripeSubscriptionsDto, any>({
        path: `/stripe-subscriptions/${userId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name StripeSubscriptionsControllerPause
     * @request PATCH:/stripe-subscriptions/{id}/pause
     */
    stripeSubscriptionsControllerPause: (id: string, data: PauseStripeSubscriptionDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stripe-subscriptions/${id}/pause`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name StripeSubscriptionsControllerResume
     * @request PATCH:/stripe-subscriptions/{id}/resume
     */
    stripeSubscriptionsControllerResume: (id: string, data: ResumeStripeSubscriptionDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stripe-subscriptions/${id}/resume`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name StripeSubscriptionsControllerCancel
     * @request PATCH:/stripe-subscriptions/{id}/cancel
     */
    stripeSubscriptionsControllerCancel: (id: string, data: CancelStripeSubscriptionDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stripe-subscriptions/${id}/cancel`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name StripeSubscriptionsControllerCancelAtEnd
     * @request PATCH:/stripe-subscriptions/{id}/cancel-at-period-end
     */
    stripeSubscriptionsControllerCancelAtEnd: (
      id: string,
      data: CancelStripeSubscriptionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/stripe-subscriptions/${id}/cancel-at-period-end`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
